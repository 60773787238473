import React from 'react'

// Components
import Layout from '../../components/layout.js'
import SEO from '../../components/seo.js'

// SVGs

export default () => (
    <Layout className="project project__success-report">
        <SEO title="Toast Success Report" />
        <section className="padding-y-20 hero position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="margin-bottom-4">
                            <span className="highlight__brand-orange">
                                2019 Restaurant Success Report
                            </span>
                        </h1>
                        <h3>
                            <span className="highlight__brand-orange">
                                Toast Inc.
                            </span>
                        </h3>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-10 bg-orange-1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 align-self-center">
                        <div className="side-by-side-divider">
                            <div className="left-content margin-bottom-3">
                                <h3 className="margin-bottom-3">
                                    I developed an interactive landing-page that
                                    visualizes current diner and restaurant
                                    relationships
                                </h3>
                                <p>
                                    An industry report that defines a successful
                                    restaurant experience from start to finish.
                                    The Restaurant Success Report was a journey
                                    in disecting data from restaurant
                                    professionals and guests exploring the
                                    relationship between restaurant best
                                    practices and diner preferences.
                                </p>
                            </div>

                            <div className="divider margin-x-10"></div>
                            <div className="right-content">
                                <div className="margin-bottom-3">
                                    <h4>Timeline</h4>
                                    <p>4 Week Exploration</p>
                                    <p>2 Week Build</p>
                                </div>
                                <div className="margin-bottom-3">
                                    <h4 className="">My Role</h4>
                                    <p>Dev Lead</p>
                                </div>
                                <div className="margin-bottom-3">
                                    <h4 className="margin-bottom-1">
                                        <b>Tools</b>
                                    </h4>
                                    <div className="tag-container">
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">React</div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                React Spring
                                            </div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">D3 JS</div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">Node</div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Python
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <h4 className="">
                                        <b>Themes</b>
                                    </h4>
                                    <p>Data Visualization</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-10 bg-grey-1">
            <div className="container">
                <div className="row margin-bottom-5">
                    <div className="col-md-12">
                        <h2 className="margin-bottom-3">The Problem</h2>
                        <p>
                            Restauranteurs have different approaches to creating
                            fulfilling experiences for their guests. With
                            increasing competition and growing risk it's crucial
                            for restaurant owners to understand what draws
                            customers back to their restaurant and how they can
                            attract new customers to grow their business.
                            However, many restaurant owners are too busy or dont
                            have the means to ask diners outside of their
                            customer base. Thus this is where Toast comes in.
                        </p>
                    </div>
                </div>
                <div className="row margin-bottom-5">
                    <div className="col-md-7">
                        <h2 className="margin-bottom-3">The Solution</h2>
                        <p>
                            The team I was a part of was tasked with collecting
                            survey responses from diners and restauranteurs
                            across the United States and to aggregate their
                            responses into an interactive learning experience to
                            educate restaurant owners on the current trends in
                            the restaurant industry as well as inform them about
                            changing diner expectations.
                        </p>
                    </div>
                    <div className="col-md-4 offset-md-1">
                        <h2 className="margin-bottom-3">Goals</h2>
                        <ul className="inline-padding-none">
                            <li className="margin-bottom-2">
                                <p>
                                    Make the landing page and downloadable
                                    content informative.
                                </p>
                            </li>
                            <li>
                                <p>Make it fun.</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="margin-bottom-3">My Role</h2>
                        <p>
                            I was the lead developer creating the interactive
                            landing page. I collaborated closely with our design
                            leads and content leads to disect and refine the
                            user experience ensuring that the correct message
                            was conveyed using the appropriate graphs.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-10 overflow-hidden">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 margin-bottom-5">
                        <h2 className="">Development Process</h2>
                    </div>

                    <div className="col-md-12 row-padding">
                        <h3 className="margin-bottom-3">
                            01. - Analyze Research Insights
                        </h3>
                        <p className="margin-bottom-5">
                            After analyzing over 2,000 responses from
                            restauranteurs and diners, we discovered 3 key
                            findings.
                        </p>
                        <div className="row number-list">
                            <div className="col-lg-3 number-item margin-bottom-5">
                                <h4 className="margin-bottom-2">
                                    Managing Operating Costs and Investing in
                                    Staff Is Critical
                                </h4>
                                <p>
                                    Restaurant professionals ranked high
                                    operating and food costs and hiring staff as
                                    their top challenges to running a
                                    restaurant, with <b>52%</b> citing high
                                    operating and food costs and <b>51%</b>
                                    citing hiring staff. Training staff came in
                                    third with 35% of restaurateurs selecting it
                                    as a top challenge.
                                </p>
                            </div>
                            <div className="col-lg-3 offset-lg-1 number-item margin-bottom-5">
                                <h4 className="margin-bottom-2">
                                    Your Product Needs to Be as Good As Your
                                    Marketing
                                </h4>
                                <p>
                                    72% of guests said quality of food was
                                    important. 65% of restaurant professionals
                                    agreed, but they ranked{' '}
                                    <b>value (i.e. well-priced menus)</b> as the
                                    next most important criteria. Guests,
                                    however, ranked{' '}
                                    <b>taste and flavor preferences higher</b>.
                                </p>
                            </div>
                            <div className="col-lg-3 offset-lg-1 number-item margin-bottom-5">
                                <h4 className="margin-bottom-2">
                                    Guest Technology Is Booming
                                </h4>
                                <p>
                                    Guests listed{' '}
                                    <b>
                                        wifi availability, online reservations,
                                        and consumer ordering programs
                                    </b>
                                    as restaurant technologies most important to
                                    their guest experience. Alternatively,
                                    restaurant professionals listed{' '}
                                    <b>gift card programs</b>,{' '}
                                    <b>online ordering programs</b>, and{' '}
                                    <b>wifi availability</b> as restaurant
                                    technologies most important to their
                                    businesses.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 row-padding">
                        <h3 className="margin-bottom-3">
                            02. - Determine the Format for Supporting
                            Interactions
                        </h3>
                        <p className="margin-bottom-5">
                            With the key take aways from the survey determined,
                            it was time to decide how to best convey the
                            results. We decided to break down the message into 6
                            main supporting interactions that helped convey the
                            factors that effect a restaurant's success and
                            provide an engaging learning experience. The six
                            main interactions included:
                        </p>
                        <div className="row">
                            <div className="col-lg-6 offset-lg-1 order-lg-1 position-relative margin-bottom-10">
                                <img
                                    className="img-right-side border-full-2"
                                    src={
                                        'https://d2jotzgct8q460.cloudfront.net/imgs/projects/success-report/Success-Report-Map.png'
                                    }
                                    alt="Success Report Map"
                                />
                            </div>
                            <div className="col-lg-5 order-lg-0 margin-bottom-10">
                                <div className="row number-list">
                                    <div className="col-md-12 number-item margin-bottom-3">
                                        <p>
                                            A map displaying the city locations
                                            for our responses and the level
                                            success for restaurants in those
                                            associated cities.
                                        </p>
                                    </div>
                                    <div className="col-md-12 number-item margin-bottom-3">
                                        <p>
                                            An interactive bar chart showing the
                                            percentage of people that ranked
                                            each category in their top 3
                                            challenges.
                                        </p>
                                    </div>
                                    <div className="col-md-12 number-item margin-bottom-3">
                                        <p>
                                            A bubble chart revealing how many
                                            restaurants tackle training their
                                            staff.
                                        </p>
                                    </div>
                                    <div className="col-md-12 number-item margin-bottom-3">
                                        <p>
                                            A drag-n-drop interactive question
                                            allowing users to rank the
                                            importance of different criteria
                                            when choosing what / where to eat at
                                            a restaurant in a given week. The
                                            user can guess how the average diner
                                            and restauranteur answered and then
                                            reveal the correct ranking given the
                                            responses to our survey.
                                        </p>
                                    </div>
                                    <div className="col-md-12 number-item margin-bottom-3">
                                        <p>
                                            A stacked horizontal bar chart
                                            revealing how survey participants
                                            expressed the importance of a set of
                                            restaurant technologies to the guest
                                            expereience.
                                        </p>
                                    </div>
                                    <div className="col-md-12 number-item">
                                        <p>
                                            A mulitple choice question
                                            evaluating the types of rewards for
                                            loyalty programs aimed at retaining
                                            customers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 row-padding">
                        <div className="row">
                            <div className="col-lg-6 position-relative static-height margin-bottom-10">
                                <img
                                    className="img-left-side border-full-2"
                                    src={
                                        'https://d2jotzgct8q460.cloudfront.net/imgs/projects/success-report/Success-Report-Slider.png'
                                    }
                                    alt="Success Report Slider"
                                />
                            </div>
                            <div className="col-lg-5 offset-lg-1 align-self-center margin-bottom-10">
                                <h3 className="margin-bottom-3">
                                    03. - Format the Survey Responses Into
                                    Usable Data
                                </h3>
                                <p className="margin-bottom-5">
                                    With the questions and format for the
                                    interactions determined I needed to format
                                    the data into readable json objects to
                                    display them appropriately on a map or in a
                                    chart. To do this I wrote a number of python
                                    scripts to read in our survey responses from
                                    a csv file and appropiately merge it with
                                    topo-json data or category text.
                                </p>
                                <h3 className="margin-bottom-3">
                                    04. - Develop the Final Product and Iterate
                                    over the Design
                                </h3>
                                <p className="">
                                    The bulk of the development was done using
                                    React and D3. I decided to choose these
                                    specific tools because they allowed for fast
                                    iterative development without sacrificing
                                    functionality.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 row-padding">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-1 order-lg-1 static-height margin-bottom-5">
                                <img
                                    className="img-right-side border-full-2"
                                    src={
                                        'https://d2jotzgct8q460.cloudfront.net/imgs/projects/success-report/Success-Report-Drag-n-Drop.png'
                                    }
                                    alt="Success Report DnD"
                                />
                            </div>
                            <div className="col-lg-5 order-lg-0 align-self-center">
                                <h3 className="margin-bottom-3">
                                    05. - Publish It!
                                </h3>
                                <p className="margin-bottom-5">
                                    Once completed, we published it on the Toast
                                    website! Within the first 3 weeks received
                                    over a thousand downloads of our attached
                                    pdf download of the report!
                                </p>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://pos.toasttab.com/resources/restaurant-success-industry-report"
                                    className="primary-button primary-button__red hover"
                                >
                                    Check it Out!
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)
